import { print } from "graphql";
import axios from "../axios-client";
import gql from "graphql-tag";
import { parseErrors } from "./helpers";
import {
  OrganizationType,
  SubscriptionType,
  GraphqlError,
  OrganizationFormInput,
  UpdateSubscriptionInput,
  ProductType,
  ReferralType,
  PackageType,
} from "./interfaces";
import { env } from '../env';

const apiUrl: string = env.REACT_APP_COMMON_API_URL;

export async function fetchReferrals(filters: {
  limit: number;
  page: number;
  parentOrgId: string;
}): Promise<{ list: Array<ReferralType>; count: number }>{
  const query = gql`
    query autnhiveReferrals($limit: Int!, $page: Int!, $parentOrgId: String!) {
      autnhiveReferrals(limit: $limit, page: $page, parentOrgId: $parentOrgId) {
        list {
          id
          referral_org_name
          referral_email
          referral_expiry
          referral_status
          parent_org_name
        }
        count
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<ReferralsResponse>(`${apiUrl}/graphql`, {
      query: print(query),
      variables: filters,
    },
    {
      headers: {
        "timestamp": `${timeStamp}`,
      }
    });
    return data.data.autnhiveReferrals;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    } else {
      throw err;
    }
  }
}

export async function fetchProducts(filters: {
  limit: number;
  page: number;
}): Promise<{ list: Array<ProductType>; count: number }>{
  const query = gql`
    query autnhiveProducts($limit: Int!, $page: Int!) {
      autnhiveProducts(limit: $limit, page: $page) {
        list {
          id
          name
        }
        count
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<ProductsResponse>(`${apiUrl}/graphql`, {
      query: print(query),
      variables: filters,
    },
    {
      headers: {
        "timestamp": `${timeStamp}`,
      }
    });
    return data.data.autnhiveProducts;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    } else {
      throw err;
    }
  }
}

export async function fetchPackages(filters: {
  limit: number;
  page: number;
}): Promise<{ list: Array<PackageType>; count: number }>{
  const query = gql`
    query autnhivePackages($limit: Int!, $page: Int!) {
      autnhivePackages(limit: $limit, page: $page) {
        list {
          id
          package_code
          package_name
          product_id
          is_channel_partner
        }
        count
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<PackagesResponse>(`${apiUrl}/graphql`, {
      query: print(query),
      variables: filters,
    },
    {
      headers: {
        "timestamp": `${timeStamp}`,
      }
    });
    return data.data.autnhivePackages;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    } else {
      throw err;
    }
  }
}

export async function fetchOrganizations(filters: {
  keyword?: string;
  organization_type?: string;
  limit: number;
  page: number;
}): Promise<{ list: Array<OrganizationType>; count: number }> {
  const query = gql`
    query organizations(
      $keyword: String
      $organization_type: String
      $limit: Int!
      $page: Int!
    ) {
      organizations(
        keyword: $keyword
        organization_type: $organization_type
        limit: $limit
        page: $page
      ) {
        list {
          id
          name
          email
          phone
          is_active
          logo
          organization_type
          reseller {
            id
            name
          }
          address {
            line_1
            line_2
            city
            state
            postal_code
            country
          }
          allowed_domains
        }
        count
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<OrganizationsResponse>(
      `${apiUrl}/graphql`,
      {
        query: print(query),
        variables: filters,
      },
      {
        headers: {
          "timestamp": `${timeStamp}`,
        }
      }
    );
    return data.data.organizations;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

export async function updateOrganization(
  id: string,
  values: OrganizationFormInput
): Promise<OrganizationType> {
  const query = gql`
    mutation updateOrganization(
      $id: String!
      $name: String!
      $email: String!
      $phone: String!
      $is_active: Boolean!
      $logo: String!
      $address: AddressInput!
      $organization_type: String!
      $reseller_id: String
      $allowed_domains: [String!]
    ) {
      updateOrganization(
        id: $id
        input: {
          name: $name
          email: $email
          phone: $phone
          is_active: $is_active
          logo: $logo
          address: $address
          organization_type: $organization_type
          reseller_id: $reseller_id
          allowed_domains: $allowed_domains
        }
      ) {
        id
        name
        email
        phone
        is_active
        logo
        organization_type
        reseller {
          id
        }
        address {
          line_1
          line_2
          city
          state
          postal_code
          country
        }
        allowed_domains
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<CreateOrganizationResponse>(
      `${apiUrl}/graphql`,
      {
        query: print(query),
        variables: {
          id: id,
          name: values.name,
          email: values.email,
          phone: values.phone,
          is_active: values.is_active,
          logo: values.logo,
          address: values.address,
          reseller_id: values.reseller?.id || null,
          organization_type: values.organization_type,
          allowed_domains: values.allowed_domains,
        },
      },
      {
        headers: {
          "timestamp": `${timeStamp}`,
        }
      }
    );
    if (data.errors) {
      throw parseErrors(data.errors);
    }
    return data.data.organization;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

export async function fetchSubscriptions(
  organization_id: string,
  page: number,
  limit: number
): Promise<{ list: Array<SubscriptionType>; count: number }> {
  const query = gql`
    query subscriptions($organization_id: String!, $limit: Int!, $page: Int!) {
      subscriptions(
        organization_id: $organization_id
        page: $page
        limit: $limit
      ) {
        list {
          id
          is_active
          subscription_type
          license_type
          license_expires_at
          max_grace
          max_grace_period
          license_purchased_count
          device_license_purchased_count
          total_active_user_subscriptions
          product {
            id
            name
          }
          organization {
            id
            name
          }
        }
        count
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<SubscriptionsResponse>(
      `${apiUrl}/graphql`,
      {
        query: print(query),
        variables: { organization_id, page, limit },
      },
      {
        headers: {
          "timestamp": `${timeStamp}`,
        }
      }
    );
    return data.data.subscriptions;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

export async function updateSubscriptionStatus(
  id: string,
  is_active: boolean
): Promise<SubscriptionType> {
  const query = gql`
    mutation updateSubscriptionStatus($id: String!, $is_active: Boolean!) {
      updateSubscriptionStatus(id: $id, is_active: $is_active) {
        id
        is_active
        license_type
        license_expires_at
        license_purchased_count
        product {
          id
          name
        }
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<UpdateSubscriptionStatusResponse>(
      `${apiUrl}/graphql`,
      {
        query: print(query),
        variables: {
          id,
          is_active,
        },
      },
      {
        headers: {
          "timestamp": `${timeStamp}`,
        }
      }
    );
    if (!data.data && data.errors) {
      throw parseErrors(data.errors);
    }
    return data.data.updateSubscriptionStatus;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

export async function updateSubscription(
  id: string,
  values: UpdateSubscriptionInput
): Promise<SubscriptionType> {
  const query = gql`
    mutation updateSubscription(
      $id: String!
      $license_expires_at: Int!
      $license_purchased_count: Int!
      $device_license_purchased_count: Int!
      $license_type: String!
      $max_grace: Int!
      $max_grace_period: Int!
    ) {
      updateSubscription(
        id: $id
        input: {
          license_type: $license_type
          license_expires_at: $license_expires_at
          license_purchased_count: $license_purchased_count
          device_license_purchased_count: $device_license_purchased_count
          max_grace: $max_grace
          max_grace_period: $max_grace_period
        }
      ) {
        id
        is_active
        license_type
        license_expires_at
        license_purchased_count
        device_license_purchased_count
        total_active_user_subscriptions
        max_grace
        max_grace_period
        product {
          id
          name
        }
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<UpdateSubscriptionResponse>(
      `${apiUrl}/graphql`,
      {
        query: print(query),
        variables: {
          id,
          license_type: values.license_type,
          license_purchased_count: values.license_purchased_count,
          device_license_purchased_count: values.device_license_purchased_count,
          license_expires_at: values.license_expires_at || 0,
          max_grace: values.max_grace,
          max_grace_period: values.max_grace_period,
        },
      },
      {
        headers: {
          "timestamp": `${timeStamp}`,
        }
      }
    );
    if (data.errors) {
      throw parseErrors(data.errors);
    }
    return data.data.updateSubscription;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

export async function downloadPackage(
  organization_id: string,
  product_id: string
) {
  const timeStamp = new Date().getTime();
  const { data, headers } = await axios.get(
    `${apiUrl}/download/package/${organization_id}/${product_id}`,{ headers: { "timestamp": `${timeStamp}` } }
  );
  const url = window.URL.createObjectURL(
    new Blob([data], { type: headers["content-type"] })
  );
  let filename = "package.zip";
  const parts = headers["content-disposition"]?.split("filename=");
  filename = parts[1]?.length ? parts[1] : filename;
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
}

interface OrganizationsResponse {
  data: {
    organizations: {
      list: [OrganizationType];
      count: number;
    };
  };
}

interface ReferralsResponse {
  data: {
    autnhiveReferrals: {
      list: [ReferralType];
      count: number;
    };
  };
}

interface ProductsResponse {
  data: {
    autnhiveProducts: {
      list: [ProductType];
      count: number;
    };
  };
}

interface PackagesResponse {
  data: {
    autnhivePackages: {
      list: [PackageType];
      count: number;
    };
  };
}

interface SubscriptionsResponse {
  data: {
    subscriptions: {
      list: Array<{
        id: string;
        is_active: boolean;
        subscription_type: string;
        license_purchased_count: number;
        device_license_purchased_count: number;
        total_active_user_subscriptions?: number;
        max_grace: number;
        max_grace_period: number;
        license_type: string;
        license_expires_at: number;
        product: ProductType;
        organization: OrganizationType;
      }>;
      count: number;
    };
  };
}

interface CreateOrganizationResponse {
  data: {
    organization: OrganizationType;
  };
  errors?: Array<GraphqlError>;
}

interface UpdateSubscriptionStatusResponse {
  data: {
    updateSubscriptionStatus: SubscriptionType;
  };
  errors?: Array<GraphqlError>;
}

interface UpdateSubscriptionResponse {
  data: {
    updateSubscription: SubscriptionType;
  };
  errors?: Array<GraphqlError>;
}
