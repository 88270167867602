import React, { useEffect } from "react";
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  TextField,
  FormControlLabel,
  Checkbox,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import { Pagination } from '@mui/material';
import UserIcon from "@mui/icons-material/Person";
import BlockIcon from "@mui/icons-material/Block";
import EditIcon from "@mui/icons-material/Edit";
import AddUserIcon from "@mui/icons-material/PersonAdd";
import { Link as RouteLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as userActions from "../../actions/resellers/users";
import { GlobalReducer, Reducers } from "../../reducers";
import {
  UserType,
  CustomError,
  RoleType,
  UserFormInput,
  CreateResellerUserFormInput,
  CountryType,
} from "../../services/interfaces";
import Permissioned, { actions, resources } from "../../permissioned";
import SubmitButton from "../../components/SubmitButton";
import { useSnackbar } from "notistack";
import MandatoryFieldText from "../../components/mandatoryFieldsText";
import {
  CreateResellerUserReducer,
  ResellerUsersPageReducer,
  UpdateResellerUserReducer,
} from "../../reducers/resellers";
import { ResellersPageReducer } from "../../reducers/autnhive";
import * as userProfileActions from "../../actions/userProfile";

type ResellerUsersListProps = {
  resellerId: string;
};

type CreateResellerUserProps = {
  resellerId: string;
  onSuccess: () => void;
};

interface UserFormProps {
  disableFields?: Array<string>;
  loading: boolean;
  error?: CustomError;
  onSubmit: Function;
  roles: Array<RoleType>;
  values?: UserFormInput;
  variant: "create" | "edit";
}

interface EditResellerUserProps {
  resellerId: string;
  user: UserType;
  onSuccess: () => void;
}

const LIMIT = 20;

export function ResellerUsersList(props: ResellerUsersListProps) {
  const usersPage: ResellerUsersPageReducer = useSelector<
    Reducers,
    ResellerUsersPageReducer
  >((state) => state.reseller.usersPage);
  const globalState: GlobalReducer = useSelector<Reducers, GlobalReducer>(
    (state) => state.globe
  );
  const dispatch = useDispatch<any>();
  useEffect(() => {
    return () => {
      dispatch(userActions.resetStates());
    };
  }, [dispatch]);
  let reseller_id: string | undefined = props.resellerId;
  const isReseller = globalState.userProfile?.user.role.role_for === "reseller";
  if (isReseller) {
    reseller_id = globalState.userProfile?.user.reseller?.id;
  }
  useEffect(() => {
    if (!reseller_id) {
      return;
    }
    dispatch(
      userActions.fetchResellerUsers({
        limit: LIMIT,
        page: 1,
        keyword: "",
        reseller_id,
      })
    );
  }, [dispatch, reseller_id]);

  if (!reseller_id) {
    return null;
  }
  return <>
    <Grid container alignItems="center" spacing={2}>
      <Grid item>
        <TextField
          margin="normal"
          variant="outlined"
          size="small"
          label="Search Users"
          onChange={(e) => {
            dispatch(userActions.updateSearchKeyword(e.target.value));
          }}
          onKeyPress={(e) => {
            if (e.key !== "Enter") {
              return;
            }
            dispatch(
              userActions.fetchResellerUsers({
                reseller_id,
                keyword: usersPage.searchKeyword,
                limit: LIMIT,
                page: usersPage.page,
              })
            );
          }}
        />
      </Grid>
      <Grid item>
        <Permissioned
          resource={resources.ResellerUsers}
          action={actions.Create}
        >
          <RouteLink
            to={`/admin/autnhive/channelPartner/${reseller_id}/users/add`}
          >
            <Button
              color="secondary"
              size="small"
              variant="outlined"
              startIcon={<AddUserIcon />}
            >
              New
            </Button>
          </RouteLink>
        </Permissioned>
      </Grid>
    </Grid>
    <List>
      {usersPage.users.map((user: UserType) => {
        return (
          <ListItem key={user.id}>
            <ListItemIcon>
              <UserIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <>
                  {user.first_name} {user.last_name} |{" "}
                  {user.email.toLowerCase()}
                  {!user.is_active ? (
                    <BlockIcon style={{ marginLeft: 5, fontSize: 15 }} />
                  ) : null}
                </>
              }
              secondary={user.role.name}
            />
            <ListItemSecondaryAction>
              <Permissioned
                resource={resources.Users}
                action={actions.Update}
              >
                <RouteLink
                  to={`/admin/autnhive/channelPartner/${reseller_id}/users/${user.id}/edit`}
                >
                  <Tooltip title="Edit User">
                    <IconButton size="large">
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </RouteLink>
              </Permissioned>
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>

    <Pagination
      count={Math.ceil(usersPage.count / LIMIT)}
      page={usersPage.page}
      variant="outlined"
      shape="rounded"
      onChange={(e: React.ChangeEvent<unknown>, page: number) => {
        dispatch(
          userActions.fetchResellerUsers({
            limit: LIMIT,
            page,
            reseller_id,
            keyword: usersPage.searchKeyword,
          })
        );
      }}
    />
  </>;
}

export function UserForm(props: UserFormProps) {
  let firstNameRef: HTMLInputElement;
  let lastNameRef: HTMLInputElement;
  let emailRef: HTMLInputElement;
  let isActiveRef: HTMLInputElement;
  let roleIdRef: HTMLInputElement;
  let countryRef: HTMLInputElement;

  const dispatch = useDispatch<any>()
  const onSubmit: Function = () => {
    props.onSubmit({
      first_name: firstNameRef?.value.trim(),
      last_name: lastNameRef?.value.trim(),
      email: emailRef?.value.toLowerCase().trim(),
      is_active: isActiveRef?.checked,
      role_id: roleIdRef?.value,
      roles:[roleIdRef?.value],
      address: {
        country: countryRef.value.trim(),
      },
    });
  };
  const {countries} = useSelector<Reducers, GlobalReducer>(
    (state) => state.globe
  );
  useEffect(() => {
    if (!countries || countries.length <= 0) {
      dispatch(
        userProfileActions.fetchCountries()
      );
    }
  }, []);
  const { values } = props;
  return (
    <div>
      <form>
        <Grid container spacing={3}>
          {!values?.isAd ? null : 
          <div style={{marginTop:'10px', marginLeft: '20px', marginBottom: '-20px'}}>
            <p>First name, last name, country must be updated through AD-sync</p>
          </div>} 
          <Grid item xs={6}>
            <TextField
              margin="normal"
              variant="standard"
              error={!!props.error?.validationErrors?.first_name}
              disabled={values?.isAd}
              required
              label="First name"
              defaultValue={values?.first_name}
              inputRef={(ref) => (firstNameRef = ref)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="normal"
              variant="standard"
              error={!!props.error?.validationErrors?.last_name}
              disabled={values?.isAd}
              required
              label="Last name"
              defaultValue={values?.last_name}
              inputRef={(ref) => (lastNameRef = ref)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              margin="normal"
              variant="standard"
              error={!!props.error?.validationErrors?.email}
              required
              label="Email"
              disabled={props.disableFields?.includes("email")}
              defaultValue={values?.email}
              inputRef={(ref) => (emailRef = ref)}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl margin="normal" error={!!props.error?.validationErrors?.role_id}>
              <InputLabel variant="standard" id="select-label">Role</InputLabel>
              <Select
                variant="standard"
                error={!!props.error?.validationErrors?.role_id}
                labelId="select-role"
                style={{ width: 195 }}
                id="select-role"
                defaultValue={values?.role_id || ""}
                inputRef={(ref) => (roleIdRef = ref)}
              >
                {props.roles.map((role: RoleType) => (
                  <MenuItem key={role.id} value={role.id}>
                    {role.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <FormControl margin="normal">
              <InputLabel variant="standard">Country</InputLabel>
              <Select
                variant="standard"
                style={{ width: 195 }}
                disabled={values?.isAd}
                inputRef={(ref) => (countryRef = ref)}
                defaultValue={values?.address.country || "Canada"}
              >
                {countries?.map(
                  (country: CountryType) => (
                    <MenuItem key={country.id} value={country.name}>
                      {country.name}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <br />
        {props.variant === "edit" ? (
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  inputRef={(ref) => {
                    if (ref) {
                      isActiveRef = ref;
                    }
                  }}
                  defaultChecked={
                    values?.is_active !== undefined ? values.is_active : true
                  }
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              }
              label="Active"
            />
          </div>
        ) : null}
        <MandatoryFieldText
          hasErrors={Boolean(props.error?.validationErrors)}
        />
        <br />
        <div>
          <SubmitButton
            loading={props.loading}
            label="Submit"
            onClick={() => onSubmit()}
          />
        </div>
      </form>
    </div>
  );
}

export function CreateResellerUser({
  resellerId,
  onSuccess,
}: CreateResellerUserProps) {
  const dispatch = useDispatch<any>();
  const { enqueueSnackbar } = useSnackbar();
  const createUser: CreateResellerUserReducer = useSelector<
    Reducers,
    CreateResellerUserReducer
  >((state) => state.reseller.createUser);
  useEffect(() => {
    dispatch(userActions.fetchRoles(100, 1, { reseller_id: resellerId }));
  }, [dispatch, resellerId]);
  return (
    <UserForm
      variant="create"
      error={createUser.error}
      loading={createUser.loading}
      roles={createUser.roles}
      onSubmit={(values: CreateResellerUserFormInput) => {
        values.reseller_id = resellerId;
        Promise.resolve()
          .then(() => dispatch(userActions.createResellerUser(values)))
          .then(() =>
            enqueueSnackbar("User is created", { variant: "success" })
          )
          .then(onSuccess)
          .catch((err) => enqueueSnackbar(err.message, { variant: "error" }));
      }}
    />
  );
}

export function EditResellerUser({
  user,
  resellerId,
  onSuccess,
}: EditResellerUserProps) {
  const dispatch = useDispatch<any>();
  const { enqueueSnackbar } = useSnackbar();
  const updateUser: UpdateResellerUserReducer = useSelector<
    Reducers,
    UpdateResellerUserReducer
  >((state) => state.reseller.updateUser);
  useEffect(() => {
    dispatch(userActions.fetchRoles(100, 1, { reseller_id: resellerId }));
  }, [dispatch, resellerId]);

  return (
    <UserForm
      variant="edit"
      disableFields={["email"]}
      loading={updateUser.loading}
      values={{
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        is_active: user.is_active,
        role_id: user.role.id,
        roles:[{role_id:user.role.id}],
        address: user.address,
        isAd: user.active_directory_id ? true : false
      }}
      roles={updateUser.roles}
      onSubmit={(values: UserFormInput) => {
        Promise.resolve()
          .then(() => dispatch(userActions.updateResellerUser(user.id, values)))
          .then(() =>
            enqueueSnackbar("User is updated", { variant: "success" })
          )
          .then(onSuccess)
          .catch((err) => enqueueSnackbar(err.message, { variant: "error" }));
      }}
    />
  );
}
